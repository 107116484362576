export default ({ updateIntentUrl, csrf }) => ({
  days: "10",

  init() {
    this.$watch("days", async (newDays) => {
      await fetch(updateIntentUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrf,
        },
        body: JSON.stringify({ days: newDays }),
      }).catch((error) => {
        console.error(error);
        alert(
          "Something has gone wrong, please try again later or contact support.",
        );
      });
      this.$dispatch("intent-updated");
    });
  },
});
