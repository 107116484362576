import { isSameDay, isToday, isYesterday, format } from "date-fns";

export default (singleMessage, userId) => ({
  message: singleMessage,
  isSender: userId === singleMessage.sender.id,
  sentToday: isToday(new Date(singleMessage.created_at)),
  sentYesterday: isYesterday(new Date(singleMessage.created_at)),

  isSameDay,

  format,

  init() {
    this.$watch("singleMessage", (updatedMessage) => {
      this.message = updatedMessage;
    });
  },
});
