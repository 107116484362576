import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_KEY } from "../constants.js";

export default ({ email, redirectUrl, clientSecret }) => ({
  stripe: null,
  loading: false,
  errorMessage: null,

  async init() {
    this.stripe = await loadStripe(STRIPE_KEY);

    this.elements = this.stripe.elements({
      clientSecret,
      appearance: {
        theme: "flat",
        variables: {
          colorPrimary: "#1e95A6",
        },
      },
    });

    const paymentElement = this.elements.create("payment", {
      layout: "tabs",
      defaultValues: {
        billingDetails: {
          email: email,
        },
      },
    });

    paymentElement.mount(this.$refs.payment);
  },

  async updateForm() {
    this.loading = true;
    await this.elements.fetchUpdates();
    this.loading = false;
  },

  async onSubmit() {
    this.loading = true;

    this.errorMessage = null;

    const { error: submitError } = await this.elements.submit();
    if (submitError) {
      this.errorMessage = submitError.message;
      return;
    }

    const { error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        return_url: redirectUrl,
      },
    });

    if (error) {
      console.log(error);
      if (error.type === "card_error" || error.type === "validation_error") {
        this.errorMessage = error.message;
      } else {
        this.errorMessage = "An unexpected error occurred.";
      }

      this.loading = false;
    }
  },
});
